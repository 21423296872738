// COMMENT ALL DUE TO MIGRATING TO NEW UI
// body {
//   // font-family: $font_body;
//   line-height: $body_line_height;
//   color: $color_text_2;
//   letter-spacing: normal;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// a {
//   color: $color_brand;
//   transition: color 0.1s ease-in-out;

//   &:hover,
//   &.hover {
//     color: darken($color_brand, 20%);
//     text-decoration: none;
//   }
//   &:active {
//     color: $color_brand;
//   }
// }

// p {
//   margin-top: -5px;
//   margin-bottom: 23.4px;
// }

// .hr {
//   margin-top: 13px;
//   margin-bottom: 13px;
//   border: 0;
//   border-top: 1px solid rgba(#000, .1);
// }

// b,
// strong {
//   font-weight: 600;
// }

// small {
//   font-size: 85%;
// }

// label {
//   // margin-bottom: 9.1px;
//   // font-weight: 400;
//   color: $color_secondary;
// }

// pre {
//   display: flex;
//   flex-wrap: wrap;
// }

// code:not(.hljs) {
//   display: inline-block;
//   padding: 3px 5px;
//   color: $color_secondary;
//   background-color: $color_grey_1;
//   border-radius: 2.6px;
// }

// mark {
//   padding: 3px 6px;
//   border-radius: 2.6px;
// }

// /* headings */
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   // margin-bottom: 22.1px;
//   // font-family: $font_title;
//   // font-weight: 400;
//   line-height: $heading_line_height;
//   color: $color_text_1;
//   text-transform: none;
//   letter-spacing: normal;
// }
// h1 {
//   margin-top: -5px;
//   font-size: 22.1px;
// }
// h2 {
//   margin-top: -4px;
//   font-size: 20px;
// }
// h3 {
//   margin-top: -3px;
//   font-size: 18px;
// }
// h4 {
//   margin-top: -6px;
//   font-size: 16px;
// }
// h5 {
//   margin-top: -2px;
//   font-size: 14px;
// }
// h6 {
//   margin-top: -2px;
//   font-size: 12px;
// }

strong {
  font-weight: 600;
}