@import '../../assets/css/variables';
@import '../../assets/css/typography';

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
// @import '../../assets/css/bootstrap-custom.scss';    migrated

/*------------------------------------------------------------------

  Demo

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/demo';    migrated

/*------------------------------------------------------------------

  Base

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/base';    migrated

/*------------------------------------------------------------------

  Preloader

 -------------------------------------------------------------------*/
// @import "../../assets/css/rootui-parts/preloader";

/*------------------------------------------------------------------

  Typography

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/typography';    migrated

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap';   migrated

/*------------------------------------------------------------------

  Helpers

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/helpers';   migrated

// Layouts

/*------------------------------------------------------------------

  Navbar

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/navbar';    migrated

/*------------------------------------------------------------------

  Spotlight Mode

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/spotlight-mode';    migrated

/*------------------------------------------------------------------

  Section lines

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/section-lines';   migrated

/*------------------------------------------------------------------

  Header

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/header';    migrated

/*------------------------------------------------------------------

  Sign

 -------------------------------------------------------------------*/
// @import "../../assets/css/rootui-parts/sign";

/*------------------------------------------------------------------

  Error

 -------------------------------------------------------------------*/
// @import "../../assets/css/rootui-parts/error";

/*------------------------------------------------------------------

  Mailbox

 -------------------------------------------------------------------*/
// @import "../../assets/css/rootui-parts/mailbox";

/*------------------------------------------------------------------

  Messenger

 -------------------------------------------------------------------*/
// @import "../../assets/css/rootui-parts/messenger";

/*------------------------------------------------------------------

  Project Management

 -------------------------------------------------------------------*/
// @import "../../assets/css/rootui-parts/project-management";

/*------------------------------------------------------------------

  File Manager

 -------------------------------------------------------------------*/
// @import "../../assets/css/rootui-parts/file-manager";

/*------------------------------------------------------------------

  Profile

 -------------------------------------------------------------------*/
// @import "../../assets/css/rootui-parts/profile";

/*------------------------------------------------------------------

  Footer

 -------------------------------------------------------------------*/
// @import '../../assets/css/rootui-parts/footer';    migrated

/*------------------------------------------------------------------

  Elements

 -------------------------------------------------------------------*/

/*---------------------------
  Element Alert
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-alert';   migrated

/*---------------------------
  Element Badge
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-badge';   migrated

/*---------------------------
  Element Breadcrumb
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-breadcrumb';    migrated

/*---------------------------
  Element Buttons
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-buttons';   migrated

/*---------------------------
  Element Card
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-card';    migrated

/*---------------------------
  Element Carousel
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-carousel';    migrated

/*---------------------------
  Element Collapse
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-collapse';    migrated

/*---------------------------
  Element Table
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-table';   migrated

/*---------------------------
  Element Pagination
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-pagination';    migrated

/*---------------------------
  Element Dropdown
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-dropdown';    migrated

/*---------------------------
  Element Forms
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-forms';   migrated

/*---------------------------
  Element List group
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-list-group';    migrated

/*---------------------------
  Element Media object
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-media-object';  migrated

/*---------------------------
  Element Modal
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-modal';

/*---------------------------
  Element Navs
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-navs';    migrated

/*---------------------------
  Element Popovers
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-popovers';    migrated

/*---------------------------
  Element Progress
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-progress';    migrated

/*---------------------------
  Element Spinners
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-spinners';    migrated

/*---------------------------
  Element Toast
 ----------------------------*/
// @import "../../assets/css/rootui-parts/bootstrap-toast";

/*---------------------------
  Element Blockquote
 ----------------------------*/
// @import '../../assets/css/rootui-parts/bootstrap-blockquote';    migrated

/*---------------------------
  Element Search
 ----------------------------*/
// @import "../../assets/css/rootui-parts/element-search";

/*---------------------------
  Element Popup
 ----------------------------*/
// @import "../../assets/css/rootui-parts/element-popup";

/*---------------------------
  Element Snippet
 ----------------------------*/
// @import "../../assets/css/rootui-parts/element-snippet";

/*---------------------------
  Element Timeline
 ----------------------------*/
// @import "../../assets/css/rootui-parts/element-timeline";

/*---------------------------
  Element Changelog
 ----------------------------*/
// @import '../../assets/css/rootui-parts/element-changelog';   migrated

/*---------------------------
  Element Kanban
 ----------------------------*/
// @import '../../assets/css/rootui-parts/element-kanban';    migrated

/*---------------------------
  Element Task
 ----------------------------*/
// @import '../../assets/css/rootui-parts/element-task';    migrated

/*---------------------------
  Element Icons
 ----------------------------*/
// @import '../../assets/css/rootui-parts/element-icons';   migrated

/*---------------------------
  Element Content Boxes
 ----------------------------*/
// @import '../../assets/css/rootui-parts/element-content-boxes';   migrated

/*---------------------------
  Element Image Boxes
 ----------------------------*/
// @import '../../assets/css/rootui-parts/element-image-boxes';   migrated

/*---------------------------
  Element Widget
 ----------------------------*/
// @import "../../assets/css/rootui-parts/element-widget";

/*---------------------------
  Element Social links
 ----------------------------*/
// @import '../../assets/css/rootui-parts/element-social-links';    migrated

/*---------------------------
  Element Box Window
 ----------------------------*/
// @import "../../assets/css/rootui-parts/element-box-window";

/*---------------------------
  Element Feature
 ----------------------------*/
// @import "../../assets/css/rootui-parts/element-feature";

/*------------------------------------------------------------------

  Plugins

 -------------------------------------------------------------------*/

/*---------------------------
    Plugin Overlay Scrollbars
 ----------------------------*/
// @import '../../assets/css/rootui-parts/plugin-overlay-scrollbars';   migrated

/*---------------------------
    Plugin Emojione Area
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-emojione-area";

/*---------------------------
    Plugin Sortablejs
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-sortable";

/*---------------------------
    Plugin Swiper
 ----------------------------*/
// @import '../../assets/css/rootui-parts/plugin-swiper';   migrated

/*---------------------------
    Plugin Fullcalendar
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-fullcalendar";

/*---------------------------
    Plugin Fancybox
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-fancybox";

/*---------------------------
    Plugin Jstree
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-jstree";

/*---------------------------
    Plugin Sweetalert
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-sweetalert";

/*---------------------------
    Plugin Jqvmap
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-jqvmap";

/*---------------------------
    Plugin Datatable
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-datatable";

/*---------------------------
    Plugin DateTime Picker
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-datetime";

/*---------------------------
    Plugin DateRange Picker
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-daterange";

/*---------------------------
    Plugin Touchspin
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-touchspin";

/*---------------------------
    Plugin Ion Range Slider
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-irs";

/*---------------------------
    EasyMDE (Markdown)
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-easymde";

/*---------------------------
    Colorpicker
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-pickr";

/*---------------------------
    Plugin Chartist
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-chart-chartist";

/*---------------------------
    Plugin Selectize
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-selectize";

/*---------------------------
    Plugin Peity (chart)
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-chart-peity";

/*---------------------------
    Plugin eCharts
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-chart-echarts";

/*---------------------------
    Plugin Flot (chart)
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-chart-flot";

/*---------------------------
    Plugin Chartjs
 ----------------------------*/
// @import '../../assets/css/rootui-parts/plugin-chartjs';    migrated

/*---------------------------
    Plugin Highlight
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-highlight";

/*---------------------------
    Plugin Quill
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-quill";

/*---------------------------
    Plugin Dropzone
 ----------------------------*/
// @import "../../assets/css/rootui-parts/plugin-dropzone";

.loading-text-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.rui-sign {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .row {
      min-height: 100vh;
  }

  .rui-sign-form {
      max-width: 380px;
      padding: 30px;
  }
  .rui-sign-form-cloud {
      max-width: 400px;
      // padding: 40px;
      background-color: #fff;
      border-radius: .25rem;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .03);
  }

  .rui-sign-or {
      display: flex;
      align-items: center;
      color: rgb(133, 133, 133);

      &::before,
      &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          margin-bottom: -3px;
          border-bottom: 1px solid #e6ecf0;
      }
      &::before {
          margin-right: 20px;
      }
      &::after {
          margin-left: 20px;
      }
  }
}

.rui-gallery {
  .rui-gallery-item {
      position: relative;
      display: block;
      height: 100%;
      overflow: hidden;
      border-radius: .25rem;

      &:focus {
          outline: none;
      }


      > img {
          width: 100%;
          height: 100%;
          // stylelint-disable-next-line
          font-family: "object-fit: cover";
          object-fit: cover;
          will-change: transform;
          transition: transform .4s ease-in-out;
          transform: scale(1);
      }
  }
}

.rui-popup {
  display: none;
  padding: 30px;
  border-radius: .25rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .03);

  // @include media-breakpoint-up(lg) {
  //     &::-webkit-scrollbar-track {
  //         background-color: #fff;
  //         border-radius: .25rem;
  //     }
  //     &::-webkit-scrollbar {
  //         width: 4px;
  //         background-color: #fff;
  //         border-radius: .25rem;
  //     }
  //     &::-webkit-scrollbar-thumb {
  //         background-color: $color_grey_4;
  //         border-radius: .25rem;
  //     }
  // }
}

// Search
// .rui-popup-search {
//   position: absolute;
//   top: 100px;
//   margin-bottom: 100px;
//   will-change: transform;
//   transition: transform .15s ease-in-out;
//   transform: scale(.9) translateY(-30px) translateX(100px);

//   @include media-breakpoint-down(lg) {
//       top: 30px;
//       width: calc(100% - 60px);
//       margin-right: 30px;
//       margin-bottom: 30px;
//   }
//   @include media-breakpoint-down(sm) {
//       top: 10px;
//       width: calc(100% - 20px);
//       margin-right: 10px;
//       margin-bottom: 10px;
//   }
//   @include media-breakpoint-down(xs) {
//       top: 5px;
//       width: calc(100% - 10px);
//       margin-right: 5px;
//       margin-bottom: 5px;
//   }
// }
// .fancybox-slide--current .rui-popup-search,
// .fancybox-is-closing .rui-popup-search {
//   transform: scale(1) translateY(0) translateX(0);
// }

// Messenger
// .rui-popup-messenger {
//   position: absolute;
//   top: 20px;
//   right: 20px;
//   bottom: 20px;
//   width: 420px;
//   padding: 0;
//   overflow: hidden;

//   @include media-breakpoint-down(sm) {
//       top: 10px;
//       right: 10px;
//       bottom: 10px;
//       max-width: calc(100% - 20px);
//   }
//   @include media-breakpoint-down(xs) {
//       top: 5px;
//       right: 5px;
//       bottom: 5px;
//       width: calc(100% - 10px);
//       max-width: none;
//   }
// }

.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-position: 50% 50%;
  // height: 100%;
  background-size: cover;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    // stylelint-disable-next-line
    font-family: 'object-fit: cover';
    object-fit: cover;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: 1;
  }
}